import React, {useEffect, useMemo} from 'react'
import { PageProps } from 'gatsby'
import { LayoutProps } from '../components/Layout.context'
import {Helmet} from "react-helmet";
import styled from "styled-components";
import useSiteMetadata from "../hooks/useSiteMetadata";
import {ExternalBrandEnum} from "../data/externalBrand";

const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  line-height: 36px;
  margin: 100px 0;
  color: ${({ theme }) => theme.colors.secondary};
`
const RedirectionOrange: React.FC<PageProps> = () => {

    const siteMetadata = useSiteMetadata()

    let pathname = typeof window !== "undefined" ? window.location.pathname : ""

    const canonical = useMemo(
        () => `${siteMetadata?.siteUrlWithoutSlash}${pathname}`,
        [pathname, siteMetadata]
    )

    useEffect(() => {
        sessionStorage.clear()
        sessionStorage.setItem('fromPage', ExternalBrandEnum.ORANGE.code)

        // redirects to reparation URL
        window.location.replace('/reparation')
    }, [])

    return <>
        <Helmet>
            <meta name="robots" content="noindex"/>
            <link rel="alternate" href={canonical} hrefLang="fr-FR"/>
        </Helmet>
        <Title>Redirection vers le choix de votre modèle en cours...</Title>
    </>
}

const layoutProps: LayoutProps = {
    title: 'Save - réparation orange',
}

export default Object.assign(RedirectionOrange, {
    layoutProps,
})